/**
 * @module Functions/FunctionUtils
 */

// Utils
import axios from 'axios'
import qs from 'qs'
import moment from 'moment';

const dateFormatMappings = {
  default: 'YYYY-MM-DD HH:mm:ss', // ISO-like format as default
  'en-GB': 'DD/MM/YYYY HH:mm:ss', // UK format
  'en-US': 'MM-DD-YYYY HH:mm:ss', // US format
};


/**
 * Converts the moment object to the final API date format, rounding to the minute above and without milliseconds in UTC.
 *
 * @param {Object} momentInputDate - The moment object representing the input date.
 * @returns {string} - The final API date format in UTC.
 */
export function finalAPIDateFormat(momentInputDate) {
  const dt =  momentInputDate.toISOString().split('.')[0] + 'Z';
  return dt
}


/**
 * Parses the input date string into ISO format or other accepted formats.
 * 
 * @param {string|number} inputDate - The input date string or Excel serial date.
 * @returns {string} The parsed date string in the final API date format.
 * @throws {Error} If the date format is not recognized.
 */
export function parseDateToISO(inputDate) {

      // Define an array of accepted format string
      const formats = [
        moment.ISO_8601,
        "DD/MM/YYYY HH:mm:ss",
        "DD/MM/YYYY HH:mm",
        "DD/MM/YYYY",
        "MM-DD-YYYY HH:mm:ss",
        "MM-DD-YYYY HH:mm",
        "MM-DD-YYYY"
    ];
      // Iterate over the formats and return the first that successfully parses the inputDate
      for (const format of formats) {
          if (moment(inputDate, format, true).isValid()) {
            const momentDate = moment(inputDate, format);
              return finalAPIDateFormat(momentDate)
          }
      }
    
    // Handle Excel serial date format
    if (!isNaN(parseFloat(inputDate)) && isFinite(inputDate)) {
        const secondsInADay = 60*60*24;
        const days = Math.floor(inputDate);
        const seconds = Math.floor((inputDate - days) * secondsInADay);
        const milliseconds = Math.ceil( (inputDate - days - (seconds/secondsInADay) ) * secondsInADay * 1000)
        const momentDate = moment('1899-12-30').add(days, 'days').add(seconds, 'seconds').add(milliseconds, "milliseconds"); // Excel incorrectly assumes 1900 was a leap year
        return finalAPIDateFormat(momentDate)
    }

    // Otherwise, it may be the PI syntax for *, *-1h, *-1y etc
    if (inputDate && inputDate.length > 0 && inputDate.startsWith('*')) {
        return parsePIDateString(inputDate);
    }
    
    // Return an error or null if the format is not recognized
    throw new Error("Date format not recognized");
}


/**
 * Parses the PI syntax for time intervals and returns the corresponding date.
 * @param {string} input - PI syntax for time intervals e.g. *-1h, *-1y. Only allows integers for the time value.
 * @returns {string} - The formatted date in the final API date format.
 * @throws {Error} - If the date format is not recognized or the time unit is invalid.
 */
export function parsePIDateString(input) {
  

  // Check if the input is "*" which means now.
  if (input === '*') {
    const momentDate = moment();
    return finalAPIDateFormat(momentDate)
  }

  // Extract the unit and the value from the input string.
  const match = input.match(/\*-(\d+)([a-zA-Z]+)/);
  if (!match) {
      throw new Error('Date format not recognized');
  }

  const [, value, unit] = match;
  const amount = parseInt(value, 10);

  // Calculate the date based on the unit and subtract the value from today's date.
  let date;
  switch (unit) {
      case 's': // seconds
        date = moment().subtract(amount, 'seconds');
        break;
      case 'm': // minutes
        date = moment().subtract(amount, 'minutes');
        break;
      case 'h': // hours
        date = moment().subtract(amount, 'hours');
        break;
      case 'd': // days
        date = moment().subtract(amount, 'days');
        break;
      case 'w': // weeks
        date = moment().subtract(amount, 'weeks');
        break;
      case 'mo': // months
        date = moment().subtract(amount, 'months');
        break;
      case 'y': // years
        date = moment().subtract(amount, 'years');
        break;
      default:
        throw new Error('Invalid time unit.');
  }

  return finalAPIDateFormat(date)
}

/**
 * Converts the input date to the specified locale format based on the date setting.
 * @param {string} inputDate - The input date in the format 'YYYY-MM-DDTHH:MM:SSZ'.
 * @param {string} locale - The locale to use for formatting the date.
 * @param {string} dateSetting - The date setting to determine how the date should be converted.
 * @returns {string} The converted date in the specified locale format.
 * @throws {Error} If the date format in the result is not recognized.
 */
export function convertDateFormat(inputDate, locale, dateSetting) {

    // From 'YYYY-MM-DDTHH:MM:SSZ' to user locale format);
    if(moment(inputDate, moment.ISO_8601, true).isValid() && inputDate.length > 0){
      const format = dateFormatMappings[locale] || dateFormatMappings.default;

      // convert the date according to the date setitng
      if(dateSetting === 'databricks-table'){
        return moment.parseZone(inputDate).format(format);
      } else if(dateSetting === 'utc'){
        return moment(inputDate).utc().format(format);
      } else {
        // default to user-timezone
        return moment(inputDate).format(format);
      }
    } else {
      throw new Error("Date format in result not recognized");
    }
}

export function splitAfterNumber(inputStr) {
    // Use regex to match the number and unit separately
    // \s* allows for zero or more spaces between the number and unit
    const match = inputStr.match(/^(\d+)\s*(\D+)$/);

    if (match) {
        return {
            number: match[1], 
            unit: convertString(match[2].trim()) // trim to remove any leading/trailing spaces
        };
    }

    throw new Error('Invalid time interval format');
}
  
  
export function convertString(input) {
    // convert the shorthand time intervals to full words
    switch(input) {
        case 's':
            return 'second';
        case 'm':
            return 'minute';
        case 'h':
              return 'hour';
        case 'w':
            return 'week';
        case 'mo':
            return 'month';
        case 'd':
            return 'day';
        default:
            return input;
    }
  }

  export function convertToMinutes(number, unit) {
    // Convert the time interval to minutes
    switch(unit) {
        case 'second':
            return number / 60;
        case 'minute':
            return number;
        case 'hour':
            return number * 60;
        case 'day':
            return number * 60 * 24;
        case 'week':
            return number * 60 * 24 * 7;
        case 'month':
            return number * 60 * 24 * 30;
        default:
            throw new Error('Invalid time interval unit');
    }
  }
  
  
  
/**
 * Handles an API request.
 * 
 * @param {string} url - The URL of the API endpoint.
 * @param {string} token - The authorization token.
 * @param {Object} params - The query parameters for the request.
 * @param {Object} payload - The payload data for the request.
 * @returns {Promise<any>} - A promise that resolves to the response data.
 * @throws {Error} - If the request fails, an error is thrown.
 */
export async function handleAPIRequest(url, token, params, payload, additionalHeaders={}) {
  try {
      const headers = {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          ...additionalHeaders
      };

      const response = await axios.post(url, payload, {
          headers: headers,
          params: params, 
          paramsSerializer: params => {
              return qs.stringify(params, { arrayFormat: 'repeat' });
          }
      });

      return response.data;
  } catch (error) {
      if(error.response && error.response.data.detail){
        throw new Error(`Failed to fetch data - ${error.response.data.detail}`);
      } else{
        throw new Error(`Failed to fetch data - ${error.message}`);
      }
  }
};


/**
 * Handles an API request with a promise rather than async/await.
 * 
 * @param {string} url - The URL of the API endpoint.
 * @param {string} token - The authorization token.
 * @param {Object} params - The query parameters for the request.
 * @param {Object} payload - The payload data for the request.
 * @returns {Promise<any>} - A promise that resolves to the response data.
 * @throws {Error} - If the request fails, an error is thrown.
 */
export async function handleAPIRequestPromise(url, token, params, payload, additionalHeaders={}) {

    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        ...additionalHeaders
    };

    return axios.post(url, payload, {
        headers: headers,
        params: params, 
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    })
    .then(response => response.data)
    .catch(error => {
      if(error.response && error.response.data.detail){
        throw new Error(`Failed to fetch data - ${error.response.data.detail}`);
      } else{
        throw new Error(`Failed to fetch data - ${error.message}`);
      }
    })
};




/**
 * Converts an array object to a row format that Excel requires.
 * 
 * @param {Object} arrayObj - The array object to convert.
 * @param {Array} keysToInclude - The keys to include in the row.
 * @param {string} locale - The locale to use for formatting the date.
 * @param {string} dateSetting - The date setting to determine how the date should be converted. Default is "user-timezone".
 * @returns {Array} - The converted row data.
*/
export function parseArrayObjectToRow(arrayObj, keysToInclude, locale, dateSetting="user-timezone"){
  
        // specify the order of keys
        const order = arrayObj["schema"]["fields"].reduce( (acc, obj) => {
          if(obj["name"] !== "index"){
            acc.push(obj["name"])
          }
          return acc
        }, []) 
  
        const arrayData = arrayObj["data"].map(obj =>
          order.reduce( (acc, key) => {
            if(keysToInclude.includes(key)){
              if(key == "EventTime"){
                acc.push(convertDateFormat(obj[key], locale, dateSetting))
              } else{
                acc.push(obj[key])
              }
            }
            
            return acc
          }, [])
        );
  
        return arrayData
  }




export function splitTablename(str){
    // example - rtdip.sensors.pernis_restricted_events_float
    
    try {
          // Retireve parts by splitting string
      const parts = str.split('.');
      const businessUnit = parts[0];
      const assetSecurityType = parts[2].split('_');
      
      // Get the asset, data security level and type
      const asset = assetSecurityType[0].toLowerCase();
      const dataSecurityLevel = assetSecurityType[1].toLowerCase();
      const dataType = assetSecurityType[assetSecurityType.length - 1].toLowerCase();
    
      // Return the formatted object
      return {
        business_unit: businessUnit,
        asset: asset,
        data_security_level: dataSecurityLevel,
        data_type: dataType
      };
    } catch (error){
      throw new Error(`Unsupported table name format supplied. Please use the format 'businessunit.catalog.asset_datasecuritylevel_datatype'`);
    }

  }



/**
 * Retrieves tables, catalogs, or schemas based on the provided parameters.
 * 
 * @param {string} apiUrl - The URL of the API.
 * @param {Object} parameters - The parameters for the query.
 * @param {string} parameters.catalogName - The name of the catalog.
 * @param {string} parameters.schemaName - The name of the schema.
 * @param {string} parameters.metadataQuery - The metadata query.
 * @param {string} parameters.region - The region.
 * @returns {Promise<Array<string>>} - A promise that resolves to an array of strings representing the retrieved data.
 */
export async function getTablesAndCatalogs(apiUrl, parameters){

  // Get access token
  const access_token = await getAccessToken()
  
  // initialise parameter to determine which item to be extracted from result
  let requiredParam = ""
  let sqlQuery = ""

  // Create query based on provided params
  if (parameters["catalogName"] && parameters["schemaName"]){
      // if have catalog and schema get tables
      sqlQuery = "SHOW TABLES IN " + parameters["catalogName"] + "." + parameters["schemaName"]
      requiredParam = "tableName"
  }
  else if (parameters["catalogName"]){
    //  If have only catalog get schemas
    sqlQuery = "SHOW SCHEMAS IN " + parameters["catalogName"]
    requiredParam = "databaseName"
  } else {
    //  Else just get catalogs
    sqlQuery = "SHOW CATALOGS"
    requiredParam = "catalog"
  }

  if(parameters["metadataQuery"]){
    sqlQuery = `select table_name as \`tableName\` from \`system\`.information_schema.tables where table_catalog = "${parameters["catalogName"]}" and table_schema = "${parameters["schemaName"]}" and table_name like '%metadata%' order by table_schema, table_name`
    requiredParam = "tableName"
  }

  const params = {
    "region": parameters["region"]
  }

  // use batch route since we can use the SHOW commands
  const payload = {
    "requests": [
        {
            "url": "/sql/execute",
            "method": "POST",
            "params": {
                "region": "EMEA",
                "to_json": false
            },
            "body": {
                "sql_statement": sqlQuery,
            }
        }
    ]
}

  // if use specified warehouse in parameters, use localstorage settings in headers
  const headers = {}
  if(parameters["specifiedWarehouse"] === true){
    headers["x-databricks-server-hostname"] = localStorage.getItem("hostname")
    headers["x-databricks-http-path"] = localStorage.getItem("httpPath")
  }

  return handleAPIRequestPromise(`${apiUrl}/events/batch`, access_token, params, payload, headers)
  .then(response => {
    // unpack the data from the response
    const data = []
    for(const row of response.data[0].data){ // since sent single request to batch api
      data.push(row[requiredParam])
    }

    // If does not contain hive_metastore, add it to the catalogs response
    if(requiredParam === "catalog" && !data.includes("hive_metastore")){
      data.push("hive_metastore")
    }

    return data
  })
  .catch(error => {
      return []
  });
  
}


/**
 * Searches for tag names based on the provided parameters.
 * 
 * @param {string} apiUrl - The URL of the API.
 * @param {Object} parameters - The parameters for the search.
 * @param {string} parameters.catalog - The catalog name.
 * @param {string} parameters.search_query - The search query.
 * @param {number} parameters.page_number - The page number.
 * @param {string} parameters.tablename - The table name.
 * @param {string} parameters.region - The region.
 * @returns {Array<Array<string|null>>} - The flattened tag results containing the tag name, description, and source table.
*/
export async function searchTagNames(apiUrl, parameters) {

 // Get access token
 const access_token = await getAccessToken()

 const catalog = parameters["catalog"]
 const search_query = parameters["search_query"]
 const page_number = parameters["page_number"]
 const tablename = parameters["tablename"]
 const region = parameters["region"]

 const apiParams = {
  "region": region
 }

 let tables = []
 if(tablename){
  tables = [tablename]
 } else {
  // Get table names for catalog
  const tableQueryParams = {
    "region": region,
    "catalogName": catalog,
    "schemaName": "sensors",
    "metadataQuery": true
  }
  const tableResponse = await getTablesAndCatalogs(apiUrl, tableQueryParams)
  tables = tableResponse.map(tablename => `${catalog}.sensors.${tablename}`)
 }
 
 // Construct query from params
 const tagQuery = constructSqlQuery(tables, search_query, page_number)
 const tagApiPayload = {"sql_statement": tagQuery}
 const tagResponse = await handleAPIRequestPromise(`${apiUrl}/sql/execute`, access_token, apiParams, tagApiPayload)
 const keys = ['TagName', 'Description', 'SourceTable'];
 const flattenedTagResults = tagResponse.data.map(obj => {
   return keys.map(key => obj[key] || null);
 });

 return flattenedTagResults

}

/**
 * Constructs a SQL query for tag search based on the provided parameters.
 * @param {Array<string>} tables - The array of table names to search in.
 * @param {string} searchQuery - The search query.
 * @param {number} page - The page number.
 * @param {number} pageSize - The number of results per page. Default is 10.
 * @returns {string} - The constructed SQL query.
 */
export function constructSqlQuery(tables, search_query, page, page_size=10){
 const words = search_query.replace(/\W+/g, ' ').toLowerCase().split(/\s+/);

 // Create a regex pattern as a string from the list of words
 const escapedWords = words.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
 const regex_pattern = escapedWords.join('|');

 // Constructing conditions for ranking
 // Assign a high score for an exact match in TagName
 const exact_match_condition = `IF(LOWER(TagName) = '${search_query.toLowerCase()}', 10, 0)`

 const word_conditions = words.map(word => 
   `IF(LOWER(TagName) LIKE '%${word}%', 1, 0)` +
   ` + IF(LOWER(Description) LIKE '%${word}%', 1, 0)`
 ).join(" + ");
 
 let conditions = exact_match_condition
 if(word_conditions.length > 0){
     conditions = conditions + " + " + word_conditions
 }

 const offset = (page - 1) * page_size
   
 // Construct the query for each table and union them
 const union_queries = []
 for(const table_name of tables){
     const query = `
         SELECT TagName, Description, '${table_name}' AS SourceTable, (${conditions}) AS relevance_score
         FROM ${table_name}
         WHERE LOWER(TagName) RLIKE '${regex_pattern}'
         OR LOWER(Description) RLIKE '${regex_pattern}'
     `
     union_queries.push(query)
 }
 const combined_query = union_queries.join(" UNION ")

 // Final query with pagination
 const sql_query = `
 SELECT TagName, Description, SourceTable, relevance_score
 FROM (${combined_query}) AS combined
 ORDER BY relevance_score DESC
 LIMIT ${page_size} OFFSET ${offset}
 `

 return sql_query
}

/**
 * Retrieves the access token from the Office API.
 * @returns {Promise<string>} A promise that resolves to the access token.
 * @throws {Error} If failed to fetch the access token.
*/
export function getAccessToken(){

  // Retieve cached token from office api - handles caching behind the scenes
  // remove allowSignInPrompt as per bug https://github.com/OfficeDev/office-js/issues/3298
  return Office.auth.getAccessToken({ // NOSONAR
    allowConsentPrompt: true,
    forMSGraphAccess: true,
  }).then(accessToken => {
    return accessToken;
  }).catch(error => {
    throw new Error(`Failed to fetch access token - ${error.message}`);
  });
};
