/**
 * @module Taskpane/ExcelUtils
 */



/**
 * Selects a cell in the Excel sheet based on the provided cell name.
 * 
 * @param {string} cellName - The name of the cell to be selected.
 * @returns {Promise<void>} - A promise that resolves when the cell is successfully selected.
 */
const selectCell = async (cellName) => {
    // Takes the value of the field and selects that cell in the excel sheet
    try {     
        await Excel.run(async (context) => { //NOSONAR
            const sheet = context.workbook.worksheets.getActiveWorksheet();
            const range = sheet.getRange(cellName);
            range.select();
            await context.sync();
        });
    } catch (error) {
          // console.log(error);
    }
}


/**
 * Retrieves the address of the currently selected cell in Excel.
 * 
 * @returns {Promise<string>} The address of the selected cell.
 */
const getSelectedCell = async () => {
    let selectedCell = ""
    try {
        await Excel.run(async (context) => {
          // Get the selected range.
          const range = context.workbook.getSelectedRange();
          // Load the range address.
          range.load("address");
          // Sync the loaded properties with Excel.
          await context.sync();
          // Split the range address by the colon character to get the start cell.
          selectedCell = range.address.split(":")[0];
        });
      } catch (error) {
        console.error(error);
      }

      return selectedCell
}

/**
 * Retrieves the address of the currently selected range in Excel.
 * 
 * @returns {Promise<string>} The address of the selected cell.
 */
const getSelectedRange = async () => {
  let selectedRange = ""
  try {
      await Excel.run(async (context) => {
        // Get the selected range.
        const range = context.workbook.getSelectedRange();
        // Load the range address.
        range.load("address");
        // Sync the loaded properties with Excel.
        await context.sync();
        // Split the range address by the colon character to get the start cell.
        selectedRange = range.address
      });
    } catch (error) {
      console.error(error);
    }

    return selectedRange
}

/**
 * Checks if the given address is a valid Excel address.
 *
 * @param {string} address - The address to be validated.
 * @returns {boolean} - Returns true if the address is valid, otherwise false.
 */
function isValidExcelAddress(address) {
  try{

  // If address contains comma, then multiple cells rather than one or a range  
  if(address.includes(",")){
    return false;
  }
  
  // Regular expression to match optional sheet name and cell range
  const regex = /^(?:'?(.*?)'?!)?([A-Z]+[0-9]+)(?::([A-Z]+[0-9]+))?$/;
  const match = address.match(regex);

  if (!match) {
    return false;
  }

  const [, sheet, startCell, endCell] = match;

  // Validate the start cell address
  if (!checkExcelAddress(startCell)) {
    return false;
  }

  // If there's an end cell address, validate it too
  if (endCell && !checkExcelAddress(endCell)) {
    return false;
  }

  return true;

} catch (error) {
    return false
}
}


function checkExcelAddress(cellAddress) {
  // Check if the given cell address is a valid Excel cell address.
  const match = cellAddress.match(/^([A-Z]+)(\d+)$/);
  if (!match) {
    return false;
  }

  const [_, column, row] = match;

  let columnNumber = 0;
  for (let i = 0; i < column.length; i++) {
    columnNumber = columnNumber * 26 + (column.charCodeAt(i) - 64);
  }

  if (columnNumber < 1 || columnNumber > 16384) {
    return false;
  }

  const rowNum = parseInt(row, 10);
  if (rowNum < 1 || rowNum > 1048576) {
    return false;
  }

  return true;
}


const wrapParameterInQuotes = (parameter, parameterName) => {
  if(parameterName === "tagNames" || parameterName === "timestamps"){
    // For tagname_filter and timestamp_filter - wrap as Excel array
    const joinedVal = parameter.join('", "')
    if(joinedVal === "") return ""
    return isValidExcelAddress(joinedVal) ? parameter : `{${'"'+joinedVal+'"'}}`
  } else {
    // Normal case - wrap as Excel address or string
    return isValidExcelAddress(parameter) ? parameter : `"${parameter}"`
  }
}

const buildParameterStrings = (parameterNamesArr, parameterValuesObj) => {
  // loop over parameter names and build the parameter strings
  let output = ""
  for (let i = 0; i < parameterNamesArr.length; i++) {
    const paramName = parameterNamesArr[i]
    const paramValue = parameterValuesObj[paramName]

    // Only add if the parameter value is not null/undefined
    if (paramValue != null && paramValue !== "") {
      output += wrapParameterInQuotes(paramValue, paramName)
    }

    // Add a comma if not the last parameter
    if (i < parameterNamesArr.length - 1) {
      output += `, `
    }
  }

  return output
}


const buildFormulaString = (functionName, parameterNamesObj, parameterValuesObj) => {
  // Build the formula string from the function name and parameters

  // Create the initial part with formula name and open bracket
  let formulaString = `=RTDIP.${functionName}(`

  // Add the required parameters
  const requiredParamsArr = parameterNamesObj["required_params"]
  formulaString += buildParameterStrings(requiredParamsArr, parameterValuesObj)

  // Add the optional parameters
  const optionalParamsArr = parameterNamesObj["advanced_params"]

  // check any optional parameters are present
  const anyPresent = optionalParamsArr.some(param => parameterValuesObj[param] != null)

  if(anyPresent && optionalParamsArr.length > 0){
    formulaString += `, `
    formulaString += buildParameterStrings(optionalParamsArr, parameterValuesObj)
  }

  // Close the bracket and return the formula string
  formulaString += `)`

  return formulaString
}

/**
 * Generates the formula string and columns based on the provided parameters.
 *
 * @param {Object} parameters - The parameters object.
 * @param {Object} parameters.settings - The settings object.
 * @param {Object} parameters.params - The params object.
 * @param {Object} parameters.paramNames - The paramNames object.
 * @returns {Object} - The object containing the columns and formulaString.
 */
const formulaStringAndColumns = (parameters={settings: {}, params: {}, paramNames: {}}) => {
  const settings = parameters["settings"]
  const params = parameters["params"]
  const paramNames = parameters["paramNames"] // obj with required_params and optional_params keys

  // Intialize the formula string and columns - start as message for error handling
  let formulaString = `An error occured while parsing inputs. Please check the inputs and try again.`
  let columns = []
  // let parsedParams = {}

  try {

    // Overwrite the apiUrl in the params object with the one from settings
    const fullParams = {...params, "apiUrl": settings["apiUrl"]}

    switch(params["query_type"]){
      case "time_weighted_average":
        columns = String(params["includeDateColumn"]).toLowerCase() == "true" ? ["Date", ...params["tagNames"]] : [...params["tagNames"]] 
        formulaString = buildFormulaString("TIMEWEIGHTEDAVG", paramNames, fullParams)
        break
      case "raw":
        columns = ["Date", "Tag", "Status", "Value"]
        formulaString = buildFormulaString("RAW", paramNames, fullParams)
        break
      case "resample":
        columns = String(params["includeDateColumn"]).toLowerCase() == "true" ? ["Date", ...params["tagNames"]] : [...params["tagNames"]] 
        formulaString = buildFormulaString("RESAMPLE", paramNames, fullParams)
        break
      case "interpolate":
        columns = String(params["includeDateColumn"]).toLowerCase() == "true" ? ["Date", ...params["tagNames"]] : [...params["tagNames"]] 
        formulaString = buildFormulaString("INTERPOLATE", paramNames, fullParams)
        break
      case "circular_average":
        columns = String(params["includeDateColumn"]).toLowerCase() == "true" ? ["Date", ...params["tagNames"]] : [...params["tagNames"]] 
        formulaString = buildFormulaString("CIRCULARAVG", paramNames, fullParams)
        break
      case  "circular_standard_deviation":
        columns = String(params["includeDateColumn"]).toLowerCase() == "true" ? ["Date", ...params["tagNames"]] : [...params["tagNames"]] 
        formulaString = buildFormulaString("CIRCULARSTDEV", paramNames, fullParams)
        break
      case  "interpolation_at_time":
        columns = String(params["includeDateColumn"]).toLowerCase() == "true" ? ["Date", ...params["tagNames"]] : [...params["tagNames"]] 
        formulaString = buildFormulaString("INTERPOLATIONATTIME", paramNames, fullParams)
        break
      case  "metadata":
        columns = ["TagId", "TagName", "Description",	"UoM",	"PointSource", "msrc_id"]
        formulaString = buildFormulaString("METADATA", paramNames, fullParams)
        break
      case  "latest":
        columns = String(params["value_only"]).toLowerCase() ? [] : ["Date", "TagName", "Time",	"Status",	"Value"]
        formulaString = buildFormulaString("LATEST", paramNames, fullParams)
        break
      case  "summary":
          columns = []
          formulaString = buildFormulaString("SUMMARY", paramNames, fullParams)
          break
      case  "sql":
        columns = []
        formulaString = buildFormulaString("SQL", paramNames, fullParams)
        break
      default: 
        formulaString = "An error occured while parsing inputs. Please check the inputs and try again."
    }
  } catch (err) {
    formulaString = "An error occured while parsing inputs. Please check the inputs and try again."
  }
 
  // Include headers only if the setting is true
  columns = String(settings["includeHeaders"]) === "true" ? columns : []

  return {
    columns: columns,
    formulaString: formulaString
  }
}


/**
 * Adds a formula to a cell in Excel.
 * 
 * @param {Object} parameters - The parameters for adding the formula.
 * @param {Object} parameters.settings - The settings object.
 * @param {Object} parameters.params - The params object.
 * @param {Object} parameters.paramNames - The paramNames object.
 * @returns {Promise<void>} - A promise that resolves when the formula is added to the cell.
 */
const addFormulaToCell = async (parameters) => {

  const {columns, formulaString} = formulaStringAndColumns(parameters);

  const headerSettings = parameters["settings"]["headerSettings"] ? parameters["settings"]["headerSettings"] : {};
  const backgroundColor = headerSettings.backgroundColor ? headerSettings.backgroundColor : "blueviolet";
  const textColor = headerSettings.textColor ? headerSettings.textColor : "#ffffff";

  try {

    await Excel.run(async (context) => {

      // Retrieve the cell and load range properties
      const [sheetName, cellAddress] = parameters["params"]["outputCell"].split("!");
      const sheet = context.workbook.worksheets.getItemOrNullObject(sheetName);
      const range = sheet.getRange(cellAddress);
      range.load();
      await context.sync();
      
      // Determine the start point in numeric coordinates
      const startRow = Number(range.rowIndex) + 1;
      const startCol = Number(range.columnIndex)

      // Insert table headers at original row
      if(columns.length > 0){
        const startCellAddress = numberToExcelCol(startCol) + startRow;
        const endCellAddress = numberToExcelCol(startCol + columns.length - 1) + startRow;
        const colRange = sheet.getRange(startCellAddress + ":" + endCellAddress);
        colRange.values = [columns]; 
        colRange.format.columnWidth = 90;

        // Set the background color and text color if not transparent
        if(backgroundColor !== "transparent"){
          colRange.format.fill.color = backgroundColor;
        }
        if(textColor !== "transparent"){
          colRange.format.font.color = textColor;
        }
      }

      // Insert the formula offset by 1 row if there are columns defined
      const formulaCellAddress = columns.length > 0 ? numberToExcelCol(startCol) + (startRow + 1) : numberToExcelCol(startCol) + startRow;
      const formulaRange = sheet.getRange(formulaCellAddress);
      formulaRange.formulas = [[ formulaString ]];

      await context.sync();
    }); 

  } catch (error) {
    console.log(error)
  }

}

/**
 * Refreshes a cell in Excel with a new formula.
 * 
 * @param {string} cell - The cell reference (e.g., "A1") to refresh.
 * @param {string} formula - The new formula to set in the cell.
 * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating whether the cell was successfully refreshed.
 */
const refreshCell = async (cell, formula) => {
  let success = false
  try {

    await Excel.run(async (context) => {

      // Retrieve the cell and load range properties
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const range = sheet.getRange(cell);
      range.load("formulas");
      await context.sync();

      // Check if the formula in the cell matches the formula provided
      if(range.formulas[0][0] === formula){
        // recalculate the specific cell
        range.calculate()
        await context.sync();

        // If the formula was successfully refreshed, return true
        success = true
      }
    }); 

  } catch (error) {
    console.log(error)
  }

  return success
}

/**
 * Clears the content of a cell in Excel.
 * 
 * @param {string} address - The address of the cell to clear.
 * @returns {Promise<void>} - A promise that resolves when the cell content is cleared.
 */
const clearCellContent = async (address) => {
  try {
    await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const range = sheet.getRange(address);
  
      range.clear();
  
      await context.sync();
  });
  } catch (error) {
    console.log(error)
  }
 
}
/**
 * Finds all formulas in the active worksheet of an Excel workbook that match a specific pattern.
 * 
 * @async
 * @function findFormulas
 * @returns {Promise<{formulas: Array<{content: string, value: any, indices: Array<number>, address: string}>, sheetName: string}>} - A promise that resolves to an object containing the formulas with their location and the name of the active worksheet.
 * @throws {Error} If an error occurs during the execution.
 */
const findFormulas = async () => {
  let formulasWithLocation = []
  let sheetName = ""
  try {

      await Excel.run(async (context) => {

        // load required information from excel
        const sheet = context.workbook.worksheets.getActiveWorksheet();
        const range = sheet.getUsedRange();
        range.load("formulas");
        range.load("address")
        range.load("values")
        sheet.load("name")
        await context.sync();

        // extract the sheet name
        sheetName = sheet.name

        // Extract params from range object
        const workBookContents = range.formulas
        const rangeStart = range.address;

        // Extract the starting column and row from the range
        const match = rangeStart.match(/!([A-Z]+)(\d+)/);
        const startColumn = columnToNumber(match[1]);
        const startRow = parseInt(match[2], 10);

        // Define the matching pattern for formula in cell
        const pattern = /=RTDIP/;

        // Find all formulas in the range that match the pattern
        formulasWithLocation = workBookContents.reduce((acc, subArray, subArrayIndex) => {
          // content looks like [[formula1, formula2], [formula3, formula4]] etc
          const matches = subArray
              .map((content, itemIndex) => ({
                  content,
                  value: range.values[subArrayIndex][itemIndex],
                  indices: [startRow + subArrayIndex, startColumn + itemIndex],
                  address: numberToExcelCol(startColumn + itemIndex - 1) + (startRow + subArrayIndex) 
              }))
              .filter(({ content }) => pattern.test(content));

          return acc.concat(matches);
        }, []);

      })
      return {formulas: formulasWithLocation, sheetName: sheetName}
  }
  catch (error) {
      //handle error by returning defaults of empty array and empty string
      return {formulas: formulasWithLocation, sheetName: sheetName}
  }
}

function addSheetIfNotPresent(input, sheetname) {
        
  if(!input.match(/^[A-Za-z0-9]+!/)){
    return sheetname + "!" + input;
  }
  // Otherwise, return unchanaged
  return input
}


function parseTopLevelItems(inputStr) {
  let result = [];
  let currentItem = '';
  let depth = 0;

  // Remove outer parentheses if they exist
  if (inputStr.startsWith('(') && inputStr.endsWith(')')) {
      inputStr = inputStr.slice(1, -1);
  }

  for (let i = 0; i < inputStr.length; i++) {
      let char = inputStr[i];

      if (char === ',' && depth === 0) {
          if (currentItem.trim() === '') {
              result.push(null);
          } else {
              result.push(currentItem.trim());
          }
          currentItem = '';
      } else {
          if (char === '{' || char === '[' || char === '(') {
              depth++;
          } else if (char === '}' || char === ']' || char === ')') {
              depth--;
          }
          currentItem += char;
      }
  }

  // Handle the last item
  if (currentItem.trim() !== '') {
      result.push(currentItem.trim());
  } else {
      result.push(null);
  }

  return result;
}


function splitFunctionString(inputStr) {
  // Remove the leading '=' if it exists
  if (inputStr.startsWith('=')) {
      inputStr = inputStr.slice(1);
  }

  // Find the index of the first dot and the first parenthesis
  const firstDotIndex = inputStr.indexOf('.');
  const firstParenIndex = inputStr.indexOf('(');

  // Extract the function name part
  const funcName = inputStr.slice(firstDotIndex + 1, firstParenIndex).trim();

  // Extract the arguments string within the brackets
  const argsStr = inputStr.slice(firstParenIndex + 1, -1); // Exclude the closing ')'

  return {
      funcName: funcName.toLowerCase(),
      argsStr: argsStr
  };
}

function parseArrayString(input) {
  // Step 1: Remove the curly braces and trim any extra whitespace
  let str = input.slice(1, -1).trim();

  let result = [];
  let currentElement = '';
  let insideQuotes = false;

  for (let char of str) {
      if (char === '"') {
          insideQuotes = !insideQuotes; // Toggle quote state
      } else if (char === ',' && !insideQuotes) {
          result.push(currentElement.trim());
          currentElement = ''; // Reset current element
      } else {
          currentElement += char; // Add char to current element
      }
  }

  // Add the last element
  if (currentElement) {
      result.push(currentElement.trim());
  }

  return result
}

function parseArgsIntoObj(args, expectedQueryParams) {
  
  const parameters = {};
  for (let i = 0; i < expectedQueryParams.length; i++) {

      // map the arguments to the query type fields
      let paramName = expectedQueryParams[i];
      let arg = args[i];

      // remove quptes around quotes
      if (arg && arg.startsWith('"') && arg.endsWith('"')) {
          // remove any double quotes
          arg = arg.slice(1, -1)
      }

      if (paramName === "tagNames" || paramName === "timestamps") {
          // If the argument is an array string, parse it into an array
          if(arg.startsWith('{') && arg.endsWith('}')){
            parameters[paramName] = parseArrayString(arg);
          } else{
            parameters[paramName] = [arg];
          }
      } else if (arg === undefined){
          parameters[paramName] = null
      }
      else {
          parameters[paramName] = arg
      }
  }

  return parameters;
}

/**
 * Parses a formula string and returns the parameters and settings.
 *
 * @param {string} formula - The formula string to parse.
 * @param {string} sheetName - The name of the sheet.
 * @param {object} queryTypes - The object containing query types.
 * @param {object} queryTypeMapping - The object containing query type mappings.
 * @returns {object} - The parsed parameters and settings.
 */
function parseFormulaString(formula, sheetName, queryTypes, queryTypeMapping) {
  // Split the formula string into function name and arguments which are in brackets
  const { funcName, argsStr } = splitFunctionString(formula);

  // Parse the arguments into an array
  const args = parseTopLevelItems(argsStr);

  // Get the expected query arguments for the function - unpack required and optional params
  const requiredParams = queryTypes[queryTypeMapping[funcName]]["required_params"];
  const advancedParams = queryTypes[queryTypeMapping[funcName]]["advanced_params"];
  const expectedQueryArgs = [...requiredParams, ...advancedParams];

  // Parse the arguments into an object
  const parameters = parseArgsIntoObj(args, expectedQueryArgs);

  // prepare settings output
  const settings = {apiUrl: parameters["apiUrl"], sheetName: sheetName}

  // prepare parameters output
  parameters["query_type"] = queryTypeMapping[funcName]
  delete parameters["apiUrl"] // remove apiUrl from parameters

  return { params: parameters, settings: settings };
}

// Convert a column string (e.g., 'B', 'AG') to its numerical equivalent
const columnToNumber = (str) => {
  let number = 0;
  for (let i = 0; i < str.length; i++) {
    // Initlaly check if capital letter in english alphabet
    if( /^[A-Z]$/.test( str.charAt(i) )){
      number = number * 26 + (str.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
    } else {
      break
    }
  }
  return number;
}

const numberToExcelCol = (n) => {
  // Utility function to convert a number to excel column name (i.e. 0 => A)
  let result = '';

  if(Number.isInteger(n)){
    while (n >= 0) {
        const remainder = n % 26;
        result = String.fromCharCode(65 + remainder) + result;
        n = Math.floor(n / 26) - 1;
    }
  }

  return result;

}


/**
 * Adds click handlers to Excel and runs a callback.
 * @param {Function} callback - The callback function to be executed when a click event occurs.
 * @returns {Promise} - A promise that resolves with the result of adding the click handlers.
 */
const addClickHandlers = async (callback) => {
  let currentFunc;
  let eventResults;
  await Excel.run(async (context) => {
      const workbook = context.workbook;
      workbook.load("worksheets");
      await context.sync();
      
      // Store the handler in a ref so it can be removed later
      currentFunc = (event) => {
        return Excel.run(async (context) => {
            // get address
            let range = context.workbook.getSelectedRange();
            range.load("address")
            await context.sync();

            // run the callback
            await callback(range.address)
            return context.sync();
        });
    };  

      // Add on single clicked event to every sheet
      const worksheetEventResults = workbook.worksheets.items.map((sheet) => {
        return sheet.onSingleClicked.add(currentFunc);
      });
    
      // Add on selection changed event to whole workbook
      const workbookEventResult = workbook.onSelectionChanged.add(currentFunc);

      // sync the context to apply the changes
      await context.sync();

      eventResults = [workbookEventResult, ...worksheetEventResults]
  });

  return eventResults;
};

/**
 * Removes the click handler for a given event result.
 * 
 * @param {OfficeExtension.EventResult} eventResult - The event result object.
 * @returns {Promise<void>} - A promise that resolves when the click handler is removed.
 */
async function removeClickHandler(eventResult) {
    await Excel.run(eventResult.context, async (context) => {
      eventResult.remove();
      await context.sync();
    });
}



exports.selectCell                      = selectCell;
exports.getSelectedCell                 = getSelectedCell;
exports.getSelectedRange                = getSelectedRange;
exports.addFormulaToCell                = addFormulaToCell;
exports.findFormulas                    = findFormulas;
exports.clearCellContent                = clearCellContent;
exports.refreshCell                     = refreshCell;
exports.formulaStringAndColumns         = formulaStringAndColumns;
exports.columnToNumber                  = columnToNumber;
exports.numberToExcelCol                = numberToExcelCol;
exports.isValidExcelAddress             = isValidExcelAddress;
exports.addClickHandlers                = addClickHandlers;
exports.removeClickHandler              = removeClickHandler;
exports.parseFormulaString              = parseFormulaString;
exports.addSheetIfNotPresent            = addSheetIfNotPresent;